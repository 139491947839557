import * as React from "react";
import { useRenderInfo } from "@uidotdev/usehooks";

const RenderInfo = () => {
  const renderInfo = useRenderInfo("RenderInfo");

  return (
    <section>
      <h1>RenderInfo</h1>
      {typeof renderInfo === "object"
        ? Object.keys(renderInfo).map((key) => {
            return (
              <tr key={key} className="text-left">
                <th className="pr-1">{key}</th>
                <td>{`${renderInfo[key]}`}</td>
              </tr>
            );
          })
        : typeof renderInfo}
    </section>
  );
};

export default RenderInfo;
