const DeviceType = Object.freeze({
  MOBILE: "mobile",
  TABLET: "tablet",
  DESKTOP: "desktop",
});

const MessageType = Object.freeze({
  INITIALIZE: "initialize",
  DISCONNECTED: "disconnected",
  ERROR: "error",
  APP_INFO: "appInfo",
  CLIENT_INFO: "clientInfo",
  LOCAL_NETWORKS: "localNetworks",
  NETWORK_NAME: "networkName",
  CLIENT_NAME: "clientName",
  CHAT: "chat",
  TRANSFER: "transfer",
  ACTION: "action",
  NETWORK: "network",
  PING: "ping",
  RTC_DESCRIPTION: "rtcDescription",
  RTC_CANDIDATE: "rtcCandidate",
  ENCRYPTED: "encrypted",
});

const ActionMessageActionType = Object.freeze({
  ACCEPT: "accept",
  CANCEL: "cancel",
});

const ClientModel = Object.freeze({
  clientId: "",
  clientName: "",
  publicKey: "",
  isLocal: false,
  deviceType: undefined,
});

const NetworkModel = Object.freeze({
  name: "",
  clients: [],
});

const MessageModel = Object.freeze({
  type: "",
  secure: undefined,
});

const TargetedMessageModel = Object.freeze({
  type: "",
  secure: undefined,
  targetId: "",
});

const InitializeMessageModel = Object.freeze({
  type: "",
  secure: undefined,
  secret: "",
  publicKey: "",
});

const DisconnectedMessageModel = Object.freeze({
  type: "",
  secure: undefined,
  reason: "",
});

const ErrorMessageModel = Object.freeze({
  type: "",
  secure: undefined,
  mode: "network",
  reason: "",
});

const AppInfoMessageModel = Object.freeze({
  type: "",
  secure: undefined,
  remoteAddress: "",
  maxSize: 0,
  noticeText: "",
  noticeUrl: "",
  appName: "",
  abuseEmail: "",
  requireCrypto: false,
});

const ClientInfoMessageModel = Object.freeze({
  type: "",
  secure: undefined,
  clientId: "",
  suggestedClientName: "",
  suggestedNetworkName: "",
  localNetworks: [],
  rtcConfiguration: undefined,
});

const LocalNetworksMessageModel = Object.freeze({
  type: "",
  secure: undefined,
  localNetworks: [],
});

const NetworkNameMessageModel = Object.freeze({
  type: "",
  secure: undefined,
  networkName: "",
  deviceType: undefined,
});

const ClientNameMessageModel = Object.freeze({
  type: "",
  secure: undefined,
  clientName: "",
});

const TransferMessageModel = Object.freeze({
  type: "",
  secure: undefined,
  targetId: "",
  transferId: "",
  fileName: "",
  fileSize: 0,
  fileType: "",
  clientId: undefined,
  preview: "",
});

const ActionMessageModel = Object.freeze({
  type: "",
  secure: undefined,
  targetId: "",
  transferId: "",
  action: "",
  clientId: undefined,
});

const NetworkMessageModel = Object.freeze({
  type: "",
  secure: undefined,
  clients: [],
});

const PingMessageModel = Object.freeze({
  type: "",
  secure: undefined,
  timestamp: 0,
});

const RTCMessageModel = Object.freeze({
  type: "",
  secure: undefined,
  targetId: "",
  data: undefined,
  transferId: "",
  clientId: undefined,
});

const RTCDescriptionMessageModel = Object.freeze({
  type: "",
  secure: undefined,
  targetId: "",
  data: undefined,
  transferId: "",
  clientId: undefined,
});

const RTCCandidateMessageModel = Object.freeze({
  type: "",
  secure: undefined,
  targetId: "",
  data: undefined,
  transferId: "",
  clientId: undefined,
});

const ChatMessageModel = Object.freeze({
  type: "",
  secure: undefined,
  clientId: undefined,
  targetId: "",
  message: "",
  direct: undefined,
});

const EncryptedMessageModel = Object.freeze({
  type: "",
  secure: undefined,
  targetId: "",
  payload: "",
  clientId: undefined,
});

const ActionType = Object.freeze({
  SET_ERROR: "SET_ERROR",
  SET_TAB: "SET_TAB",
  SET_CONNECTED: "SET_CONNECTED",
  SET_RTC_CONFIGURATION: "SET_RTC_CONFIGURATION",
  SET_NETWORK_NAME: "SET_NETWORK_NAME",
  SET_CLIENT_ID: "SET_CLIENT_ID",
  SET_CLIENT_NAME: "SET_CLIENT_NAME",
  SET_SUGGESTED_NETWORK_NAME: "SET_SUGGESTED_NETWORK_NAME",
  SET_LOCAL_NETWORK_NAMES: "SET_LOCAL_NETWORK_NAMES",
  SET_REMOTE_ADDRESS: "SET_REMOTE_ADDRESS",
  SET_NETWORK: "SET_NETWORK",
  SET_MAX_SIZE: "SET_MAX_SIZE",
  SET_NOTICE: "SET_NOTICE",
  SET_KEY_PAIR: "SET_KEY_PAIR",

  SET_APP_NAME: "SET_APP_NAME",
  SET_ABUSE_EMAIL: "SET_ABUSE_EMAIL",

  DISMISS_ERROR: "DISMISS_ERROR",

  PREPARE_MESSAGE: "PREPARE_MESSAGE",
  WS_SEND_MESSAGE: "WS_SEND_MESSAGE",
  WS_MESSAGE: "WS_MESSAGE",
  WS_CONNECTED: "WS_CONNECTED",
  WS_DISCONNECTED: "WS_DISCONNECTED",
  WS_CONNECT: "WS_CONNECT",

  ADD_TRANSFER: "ADD_TRANSFER",
  REMOVE_TRANSFER: "REMOVE_TRANSFER",
  UPDATE_TRANSFER: "UPDATE_TRANSFER",
  ACCEPT_TRANSFER: "ACCEPT_TRANSFER",
  CREATE_TRANSFER: "CREATE_TRANSFER",
  CANCEL_TRANSFER: "CANCEL_TRANSFER",

  ADD_ICE_CANDIDATE: "ADD_ICE_CANDIDATE",
  SET_LOCAL_DESCRIPTION: "SET_LOCAL_DESCRIPTION",
  SET_REMOTE_DESCRIPTION: "SET_REMOTE_DESCRIPTION",

  ADD_CHAT_ITEM: "ADD_CHAT_ITEM",
  SEND_CHAT_MESSAGE: "SEND_CHAT_MESSAGE",

  SET_AUTO_ACCEPT: "SET_AUTO_ACCEPT",
});

const FileType = Object.freeze({
  UNKNOWN: "unknown",
  TEXT: "text",
  ARCHIVE: "archive",
  IMAGE: "image",
  VIDEO: "video",
  AUDIO: "audio",
  BINARY: "binary",
});

const TransferState = Object.freeze({
  INCOMING: "incoming",
  OUTGOING: "outgoing",
  CONNECTING: "connecting",
  CONNECTED: "connected",
  IN_PROGRESS: "inProgress",
  COMPLETE: "complete",
  FAILED: "failed",
});

const TransferModel = Object.freeze({
  transferId: "",
  fileName: "",
  fileSize: 0,
  fileType: "",
  receiving: false,
  file: null,
  blobUrl: "",
  clientId: "",
  peerConnection: null,
  offset: 0,
  speed: 0,
  time: 0,
  timeLeft: 0,
  preview: "",
  state: TransferState.INCOMING,
});

const ChatItemModel = Object.freeze({
  id: "",
  date: null,
  clientId: "",
  message: "",
});

const TransferUpdateModel = Object.freeze({
  transferId: "",
});
const ActionModel = Object.freeze({
  type: null,
  value: null,
});

export {
  MessageType,
  DeviceType,
  FileType,
  ActionType,
  ActionModel,
  TransferUpdateModel,
  TransferState,
  ChatItemModel,
  ActionMessageActionType,
  RTCMessageModel,
  TransferModel,
  TargetedMessageModel,
  ClientModel,
  NetworkModel,
  MessageModel,
  InitializeMessageModel,
  DisconnectedMessageModel,
  ErrorMessageModel,
  AppInfoMessageModel,
  ClientInfoMessageModel,
  LocalNetworksMessageModel,
  NetworkNameMessageModel,
  ClientNameMessageModel,
  TransferMessageModel,
  ActionMessageModel,
  NetworkMessageModel,
  PingMessageModel,
  RTCDescriptionMessageModel,
  RTCCandidateMessageModel,
  ChatMessageModel,
  EncryptedMessageModel,
};
