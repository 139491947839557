const Footer = () => {
  return (
    <footer className="text-gray-600 body-font pt-24">
      <div className="bg-gray-100">
        <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
          <p className="text-gray-500 text-sm text-center sm:text-left">
            {`© ${new Date().getFullYear()} Meir Knapp. All Rights Reserved.`}
          </p>
          {/* <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
            <a className="text-gray-500">
              <svg
                fill="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              ></svg>
            </a>
          </span> */}
        </div>
      </div>
    </footer>
  );
};
export default Footer;
