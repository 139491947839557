// import { makeAutoObservable, runInAction } from "mobx";
import { MessageType } from "../utils/types.js";

// import type { Connection } from "./Connection.js";
import { defaultAppName } from "../config.js";
import { isClipboardReadSupported, isSafari } from "../utils/browser.js";

export class ApplicationStore {
  error = undefined;
  suggestedNetworkName = undefined;
  noticeText = undefined;
  noticeUrl = undefined;
  appName = defaultAppName;
  abuseEmail = undefined;
  tab = "transfers";
  modal = undefined;
  showPaste = false;

  constructor(connection) {
    // makeAutoObservable(this);

    connection.on("message", (message) => this.onMessage(message));
    this.refreshClipboardStatus();
  }

  openModal(modal) {
    this.modal = modal;
  }

  closeModal() {
    this.modal = undefined;
  }

  setTab(tab) {
    this.tab = tab;
  }

  share(url) {
    if (!("share" in navigator)) {
      return;
    }

    navigator.share({
      title: this.appName + " - transfer files",
      url,
    });
  }

  // private handlePermissionState(state) {
  //   this.showPaste = state !== 'denied';
  // }

  async refreshClipboardStatus() {
    if (!isClipboardReadSupported) {
      this.showPaste = false;
      return;
    }

    if (isSafari) {
      this.showPaste = true;
      return;
    }

    try {
      const permissionStatus = await navigator.permissions.query({
        name: "clipboard-read",
        allowWithoutGesture: false,
      });
      this.handlePermissionState(permissionStatus.state);
      permissionStatus.onchange = () => {
        this.handlePermissionState(permissionStatus.state);
      };
    } catch {
      // runInAction(() => {
      this.showPaste = false;
      // });
    }
  }

  async onMessage(message) {
    switch (message.type) {
      case MessageType.APP_INFO:
        // runInAction(() => {
        this.appName = message.appName || defaultAppName;
        this.abuseEmail = message.abuseEmail;
        this.noticeText = message.noticeText;
        this.noticeUrl = message.noticeUrl;
        // });

        break;
      case MessageType.CLIENT_INFO:
        // runInAction(() => {
        this.suggestedNetworkName = message.suggestedNetworkName;
        // });
        break;
      default:
        console.error("reached default onMessage Application Store");
    }
  }
}
