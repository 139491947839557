import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import DonationPage from "./pages/DonatePage/donate";
import SharePage from "./pages/SharePage/share";
import TechnicalInformation from "./pages/SharePage/TechnicalInformation";
import MainSharePage from "./pages/SharePage/MainSharePage";
import LoadingPage from "./parts/LoadingPage/loading";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/donate",
    element: <DonationPage />,
  },
  {
    path: "/share",
    element: <SharePage />,
    children: [
      {
        path: "tech/",
        element: <TechnicalInformation />,
      },
      {
        path: ":networkName/",
        element: <MainSharePage />,
      },
    ],
  },
  // {
  //   path: "/*",
  //   element: <App />,
  // },
]);

export default router;
