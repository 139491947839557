import React, { useEffect } from "react";
import { Router, Route, Link, Switch } from "wouter";
import TechnicalInformation from "./TechnicalInformation";
import MainSharePage from "./MainSharePage";
import { useLocation } from "wouter";
import "./share.css";
const { customRandom, urlAlphabet, random } = require("nanoid");
const nanoid = customRandom(urlAlphabet, 10, random);

const SharePage = () => {
  const [location, setLocation] = useLocation();

  const setPath = () => {
    const random_id = nanoid();
    setLocation(`/${random_id}`);
  };
  return (
    <div>
      {/* <SharePageRouter /> */}
      <Switch>
        <Route path="/tech" component={TechnicalInformation} />
        <Route path="/:networkName" component={MainSharePage} />
        <Route>
          This is rendered when nothing above has matched
          <br />
          {/* {setPath()} */}
          <br />
        </Route>
      </Switch>
      <>SharePage</>
      <br />
      <br />
      {/* <Link to="/" /> */}
      <Link to="~/">Back to Home</Link>
      <br />
      <Link to="/tech">technical page</Link>
      <br />
      <Link to="/test">test connection page</Link>
      {/* <RouterProvider router={router} /> */}
    </div>
  );
};

export default SharePage;
