// import { BrowserLocationHook } from "wouter/use-browser-location";
import { Link, useRoute } from "wouter";

import "./ActiveLink.css";
/*
 * Like <Link /> but adds "active" class to links that match the route
 */
export const ActiveLink = ({
  asChild, // active links can't be composed because we're adding a class
  ...props
}) => {
  const [isActive] = useRoute(props.href);

  return <Link {...props} className={isActive ? "active" : ""} />;
};
