import React, { useEffect, useState } from "react";
import logo from "../../assets/images/MeirProfile.png";
import usePageLoad from "../../utils/usePageLoaded";
// import useToggle from "../../utils/useToggle";
import "./loading.css";
const LoadingPage = () => {
  const { pageLoaded, hideOverlayLayer } = usePageLoad(5000);

  useEffect(() => {
    const updateBodyClass = () => {
      if (pageLoaded) {
        document.body.classList.remove("loading");
        document.body.classList.add("loaded");
      } else {
        document.body.classList.add("loading");
        document.body.classList.remove("loaded");
      }
    };
    setTimeout(() => {
      updateBodyClass();
    }, hideOverlayLayer);
  }, [pageLoaded]);

  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  //   const isToggled = useToggle(5000);
  // pageLoaded && hideOverlayLayer &&
  return (
    <div className={`overlay ${hideOverlayLayer ? "hide" : "show"} `}>
      <img
        className={`max-w-[20vw] h-auto ${imageLoaded ? "animate-fadeIn" : ""}`}
        src={logo}
        alt="Meir's Profile pic"
        onLoad={handleImageLoad}
      />
    </div>
  );
};

export default LoadingPage;
