import React, { useEffect, useState } from "react";
// import { observer } from "mobx-react-lite";

import * as browser from "../../utils/browser.js";
// import { TextSection } from "../components/TextSection.js";
// import { connection } from "../../stores/index.js";
import fingerprint from "../../utils/detection/opfp.js";
import Detector from "../../utils/detection/index.js";
// import { getBatteryLevel, getBatteryLevelSync } from "react-native-device-info";
// import DeviceInfo from "react-native-device-info";
export const TechnicalInformation = () => {
  const [opfp, setOpfp] = useState({});
  // const remoteAddress = connection.remoteAddress;

  // const [rndi, setRndi] = useState({});

  useEffect(() => {
    async function fetchData() {
      const result = await fingerprint();
      setOpfp(result.profile);
      // try {
      //   const deviceInfoResults = await Promise.all([
      //     DeviceInfo.getBrand(),
      //     DeviceInfo.getDeviceId(),
      //     // Add more DeviceInfo functions as needed
      //   ]);

      //   // Combine the results into a single object
      //   // const rndiObject = deviceInfoResults.reduce((acc, curr, index) => {
      //   //   const key = DeviceInfoFunctions[index]; // Assuming DeviceInfoFunctions is an array of function names
      //   //   return { ...acc, [key]: curr };
      //   // }, {});

      //   setRndi({ ...deviceInfoResults });
      // } catch (error) {
      //   console.error("Error fetching data:", error);
      // }
    }
    fetchData();
  }, []);

  const info = {
    // remoteAddress,
    userAgent: navigator.userAgent,
    ...browser,
    "-----------------------------------------------": "--------------------",
    ...opfp,
    "------------------------------------------------": "--------------------",
    // rndi: "------",
  };

  return (
    <>
      {/* <TextSection> */}
      <Detector />
      <h2>Technical Information</h2>
      <section>
        <ul className="px-4">
          {Object.entries(info).map(([name, value]) => (
            <li key={name}>
              <strong>{name}:</strong> {String(value)}
            </li>
          ))}
        </ul>
      </section>
      {/* </TextSection> */}
    </>
  );
};

export default TechnicalInformation;
