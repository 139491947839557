// import markdownContent from "../markdown/list.md";
// import MyMarkdownComponent from "../utils/markdown";
import React from "react";
import ProjectList from "./projectsList";

const Index = () => {
  return (
    <div>
      <ProjectList />
    </div>
  );
};

export default Index;
