import React from "react";

// import styles from './Toggle.module.scss';

// interface ToggleProps {
//   label?: string;
//   value?: boolean;
//   onChange?: (value: boolean) => void;
// }

export const Toggle = ({ label, value, onChange }) => {
  return (
    <label className={"flex flex-row"}>
      <input
        type="checkbox"
        className={"hidden1"}
        checked={value}
        onChange={(e) => onChange?.(e.target.checked)}
      />
      <span className={""}>
        <svg
          viewBox="0 0 24 24"
          role="presentation"
          aria-hidden="true"
          className={`w-10 h-10 ${
            value ? "fill-blue-500" : "fill-transparent1 fill-slate-700 "
          }`}
        >
          <path d="M9.86 18a1 1 0 01-.73-.32l-4.86-5.17a1.001 1.001 0 011.46-1.37l4.12 4.39 8.41-9.2a1 1 0 111.48 1.34l-9.14 10a1 1 0 01-.73.33h-.01z"></path>
        </svg>
      </span>
      <span className={""}>{label}</span>
    </label>
  );
};
