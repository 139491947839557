import React from "react";
import "./App.css";
import "./index.css";
import Index from "./pages";
import Footer from "./parts/footer";
import FromChecker from "./utils/sourceChecker";
// import Nav from "./parts/Nav/Nav";
// import LoadingPage from "./parts/LoadingPage/loading";
// import DOMCleanup from "./utils/DOMCleanup";

const App = () => {
  return (
    // <main className={`${pageLoaded ? "" : "loading"}`}>
    <>
      <FromChecker />
      {/* <DOMCleanup /> */}

      {/* <header>

        <FullScreenLogo />
      </header> */}
      <section className="px-8 lg:px-20 text-left pt-4">
        <h2 className="text-2xl mb-4">Coming Soon</h2>
        <Index />
      </section>
      {/* </main> */}
      <Footer />
    </>
  );
};

export default App;
