export function tryParse(json) {
  try {
    return JSON.parse(json);
  } catch {}

  return undefined;
}

const PREFIX = "filedrop_";

export function getItem(name, fallbackValue) {
  const item = localStorage.getItem(`${PREFIX}${name}`);
  if (item === null || typeof item === "undefined") {
    return fallbackValue;
  }

  return tryParse(item) ?? fallbackValue;
}

export function setItem(name, value) {
  localStorage.setItem(`${PREFIX}${name}`, JSON.stringify(value));
}
