import React, { useEffect, useState } from "react";
import usePageLoad from "../../utils/usePageLoaded";
import "./Nav.css";
import logo from "../../assets/images/MeirProfile.png";
import { ActiveLink } from "../ActiveLink/ActiveLink";

const Nav = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  const hideOverlayLayerDelay = 2000; // Set your delay here
  const { pageLoaded, hideOverlayLayer } = usePageLoad(hideOverlayLayerDelay);
  // const [toggleState, setToggleState] = useState(true);

  // useEffect(() => {
  //   // Simulating the removal of the class after a delay (replace this with your logic)
  //   const timeoutId = setTimeout(() => {
  //     const element = document.querySelector(".example");

  //     if (element) {
  //       // Remove the class
  //       element.classList.remove("example");

  //       // Triggering the animation by resetting the animation-play-state
  //       element.style.animationPlayState = "running";
  //     }
  //   }, 2000); // Adjust the delay as needed

  //   return () => clearTimeout(timeoutId); // Cleanup on component unmount
  // }, []); // Empty dependency array ensures the effect runs once after initial render

  return (
    <header
      className={`overflow-hidden border-b-2 text-gray-600 h-auto w-full body-font mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center`}
    >
      <a
        className="z-20 justify-center md:justify-normal h-16 w-16 flex title-font font-medium items-center text-gray-900"
        href="/"
      >
        <img
          className={`${imageLoaded ? "animate-translateDown " : ""}`}
          src={logo}
          alt="Meir's Profile pic"
          onLoad={handleImageLoad}
        />
        <span className="ml-3 text-xl text-nowrap">Meir's Zone</span>
      </a>
      <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
        <ActiveLink className="mr-5 hover:text-gray-700" href="/donate">
          Donate 💰
        </ActiveLink>
        <ActiveLink className="mr-5 hover:text-gray-700" href="/share">
          Share Files
        </ActiveLink>
        {/* <ActiveLink className="mr-5 hover:text-gray-700">TBA</ActiveLink>
        <ActiveLink className="mr-5 hover:text-gray-700">TBA</ActiveLink> */}
        <button className="inline-flex items-center bg-gray-900 border-0 py-1 px-3 focus:outline-none hover:bg-gray-800 rounded text-base mt-0">
          Guess?
          <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            className="w-4 h-4 ml-1"
            viewBox="0 0 24 24"
          >
            <path d="M5 12h14M12 5l7 7-7 7"></path>
          </svg>
        </button>
      </nav>
    </header>
  );
};

export default Nav;
