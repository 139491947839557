import React, { useState } from "react";
import {
  FaPaypal,
  FaBitcoin,
  FaEthereum,
  FaCoffee,
  FaPatreon,
  FaStripe,
  FaStripeS,
  FaCcStripe,
} from "react-icons/fa";

const imageSize = "w-10 h-10";
const DonationPage = () => {
  const paymentMethods = [
    {
      name: "PayPal",
      icon: <FaPaypal className={imageSize} />,
      link: "coming soon BH",
    },
    {
      name: "Zelle",
      icon: (
        <img
          src="/assets/payment/zelle.svg"
          alt="Zelle icon"
          className={imageSize}
        />
      ),
      link: "coming soon BH",
    },
    {
      name: "Cash App",
      icon: (
        <img
          src="/assets/payment/cashapp.png"
          alt="Zelle icon"
          className={imageSize}
        />
      ),
      link: "coming soon BH",
    },
    {
      name: "Stripe",
      icon: <FaStripe className={imageSize} />,
      link: "coming soon (BH)",
    },
    {
      name: "Buy me a Tea",
      icon: (
        <a href="https://www.buymeacoffee.com/meirpro" target="_blank">
          <img
            src="https://img.buymeacoffee.com/button-api/?text=Buy me a tea&emoji=&slug=meirpro&button_colour=FFDD00&font_colour=000000&font_family=Cookie&outline_colour=000000&coffee_colour=ffffff"
            alt="Buy Me A Tea"
          />
        </a>
      ),
      showText: false,
      // link: "https://www.buymeacoffee.com/meirpro",
    },
    {
      name: "Ko-fi",
      icon: (
        <img
          src="/assets/payment/kofi.svg"
          alt="Zelle icon"
          className={imageSize}
        />
      ),
      link: "coming soon (maybe)",
    },
    {
      name: "Patreon",
      icon: <FaPatreon className={imageSize} />,
      link: "coming soon (maybe)",
    },
    {
      name: "BTC",
      icon: <FaBitcoin className={imageSize} />,
      address: "BTC coming soon (hopefully)",
    },
    {
      name: "ETH",
      icon: <FaEthereum className={imageSize} />,
      address: "ETH coming soon (maybe)",
    },
    {
      name: "Other Crypto",
      icon: <FaEthereum className={imageSize} />,
      address: "Please let me know what other coins I should make a wallet for",
    },
  ];

  const [showPayment, setShowPayment] = useState(null);

  const handleToggle = (index) => {
    setShowPayment((prev) => (prev === index ? null : index));
  };

  const renderPaymentSection = (
    { name, icon, link, address, showText = true },
    index
  ) => (
    <div
      key={index}
      className="payment-section w-6/12 md:w-1/4 border-gray-200 p-3 flex flex-col justify-evenly items-center text-center"
    >
      <div
        className="flex flex-col items-center text-center justify-center"
        onClick={() => handleToggle(index)}
      >
        <span className="payment-icon">{icon}</span>
        {showText ? (
          <span className={`${address ? "text-green-500" : "text-blue-500"}`}>
            {name}
          </span>
        ) : null}
      </div>
      {showPayment === index && (
        <div className={`${address ? "text-green-500" : "text-blue-500"}`}>
          {link && (
            <div>
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-700"
              >
                {link}
              </a>
            </div>
          )}
          {address && (
            <div>
              <p className="text-green-700">{address}</p>
              {/* Add QR Code component here with the crypto address */}
            </div>
          )}
          <br />
        </div>
      )}
    </div>
  );

  return (
    <div className="donation-page bg-gray-100 p-8 mx-auto pt-16">
      <h1 className="text-3xl font-bold mb-4">Support me</h1>
      <div className="payment-options xl:py-16 lg:py-16 md:py-16 sm:py-16 px-15 w-full flex flex-wrap justify-evenly items-center content-center text-center">
        {paymentMethods.map((value, index) =>
          renderPaymentSection(value, index)
        )}
      </div>
      <h5 className=" mt-4">
        By supporting through one of the above options you give me the
        oportunity to keep improving my projects (which might be useful to you).
        Thank you so much.
      </h5>
    </div>
  );
};

export default DonationPage;
