import React, { useEffect } from "react";
import { useTranslation } from "react-i18not";

const PageSetup = () => {
  const { dir } = useTranslation();

  // Register service worker on component mount
  useEffect(() => {
    const registerServiceWorker = () => {
      if (
        "serviceWorker" in navigator &&
        process.env.NODE_ENV === "production"
      ) {
        navigator.serviceWorker
          .register("/serviceWorker.js")
          .then((registration) => {
            console.log("SW registration successful");
          })
          .catch((error) => {
            console.error("SW registration failed:", error);
          });
      }
    };

    // registerServiceWorker();
    // Clean up function to unregister service worker on component unmount
    // return () => {
    //   if ("serviceWorker" in navigator) {
    //     navigator.serviceWorker.getRegistrations().then((registrations) => {
    //       registrations.forEach((registration) => {
    //         registration.unregister();
    //       });
    //     });
    //   }
    // };
  }, []);

  // Set document body direction
  useEffect(() => {
    document.body.dir = dir;
  }, [dir]);

  return (
    <>
      {/* Your component content */}
      {/* ... */}
    </>
  );
};

export default PageSetup;
