const ProjectList = () => {
  const projectList = [
    { text: "Secret Chat - using WebSocket and encryption" },
    { text: "URL tracker (can be part of the shortener)" },
    { text: "Checking all the details of anyone who connects to the site" },
    { text: "Blog subdomain" },
    {
      text: "URL Shortener (Kutt)",
      link: "https://github.com/thedevs-network/kutt",
    },
    { text: "QR code generator" },
    {
      text: "WebRTC file sharing/chat (Snapdrop)",
      link: "https://github.com/RobinLinus/snapdrop",
    },
    { text: "Link tracking", link: "https://grabify.link/faq/features" },
    { text: "Login/Signup page" },
    { text: "Use online quick signup options (Google, Apple, Auth0, etc.)" },
    { text: "Show a list of sites I've made" },
    { text: "i18n (website translation)" },
  ];

  return (
    <ul className="text-lg leading-10 marker:text-sky-400 list-disc pl-3 space-y-1">
      {projectList.map((item, index) => (
        <li className="" key={index}>
          {item.link ? (
            <a
              href={item.link}
              className="text-gray-500 underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.text}
            </a>
          ) : (
            item.text
          )}
        </li>
      ))}
    </ul>
  );
};
export default ProjectList;
