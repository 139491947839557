import React from "react";
import { useTranslation } from "react-i18not";
// import { observer } from "mobx-react-lite";
// import { runInAction } from "mobx";
// import clsx from "clsx";

import { Toggle } from "../../../../components/Toggle.js";
import { settingsStore } from "../../../../stores/SettingsStore.js";

export const SettingsSection = () => {
  const { t } = useTranslation();
  const settings = settingsStore.settings;

  return (
    <div className={'clsx("subsection")'}>
      {settingsStore.keys.map((key) => (
        <Toggle
          key={key}
          label={t(`settings.${key}`)}
          value={settings[key]}
          onChange={(value) => (settings[key] = value)}
        />
      ))}
    </div>
  );
};
