import * as React from "react";
import { useNetworkState } from "@uidotdev/usehooks";

const NetworkInfo = () => {
  const { online, downlink, saveData, downlinkMax, effectiveType, rtt, type } =
    useNetworkState();
  const network = {
    online,
    downlink,
    saveData,
    effectiveType,
    rtt,
    downlinkMax,
    type,
  };
  // online	true
  // downlink	10
  // downlinkMax	undefined
  // effectiveType	4g
  // rtt	50
  // saveData	false
  // type	undefined

  return (
    <>
      <section>
        <h1>Network</h1>
        <table>
          <tbody>
            {Object.keys(network).map((key) => {
              return (
                <tr key={key} className="text-left">
                  <th className="pr-1">{key}</th>
                  <td>{`${network[key]}`}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </section>
    </>
  );
};

export default NetworkInfo;
