import {
  //   useNetworkState, // using
  //   useBattery, // using
  //   usePreferredLanguage, // using
  //   useRenderInfo, // using, makes some issues when deployed

  //   useCopyToClipboard,
  //   useCounter,
  //   useDebounce,
  //   useDocumentTitle, //change doc title
  //   useFavicon, //change favicon
  //   useGeolocation,
  //   useHistoryState,
  //   useHover, //control effects on hover (maybe better to do with css?)
  //   useIdle, // what to do if the user doesnt do any interaction...
  //   useIntersectionObserver,
  //   useVisibilityChange,
  //   useIsClient, //checks if use effect works, i guess this means  that the user is an actual user somehow
  //   useIsFirstRender, //
  //   useList, //has multiple options to do to the list : set, push, removeAt,insertAt, updateAt,clear
  //   useMap, //sim to above
  //   useSet, //sim to above
  //   useWindowScroll, //used to scrollTo
  //   useMeasure, //get height and width of ref'd element
  //   useMouse, //get mouse pos on screen
  //   useLongPress,
  //   useOrientation,
  //   useObjectState,
  //   useScript, //maybe checks status of script if it fails to load
  //   useSessionStorage,
  //   useLocalStorage,
  //   useThrottle,
  useLockBodyScroll,
  useWindowSize,
  useToggle,
} from "@uidotdev/usehooks";

import detectIncognito from "./detectIncognito";
import BatteryInfo from "./battery";
import NetworkInfo from "./network";
import React, { useEffect, useState } from "react";
import LangInfo from "./language";
import RenderInfo from "./renderinfo";

const LockBody = () => {
  return <>{useLockBodyScroll()}</>;
};
export default function Detectors() {
  const { width, height } = useWindowSize();
  const [toggle, setToggle] = useToggle();

  const [isIncognito, setIsIncognito] = useState({
    browserName: "unknown",
    isPrivate: "unknown",
  });

  const checkIncognito = async () => {
    try {
      const result = await detectIncognito();
      setIsIncognito(result);
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    checkIncognito();
  }, []); // Empty dependency array ensures it runs only once when the component mounts
  return (
    <>
      <h1 className="text-center">
        info that the browser can gather (this is just the tip of what is
        possible)
      </h1>
      <div className="flex flex-wrap text-center items-stretch justify-evenly">
        <NetworkInfo />
        <BatteryInfo />
        <LangInfo />
        <RenderInfo />
        <section>
          <h1>Window Size</h1>
          <table>
            <tbody>
              <tr className="text-left">
                <th className="pr-1">width</th>
                <td>{`${width}`}</td>
              </tr>
              <tr className="text-left">
                <th className="pr-1">height</th>
                <td>{`${height}`}</td>
              </tr>
            </tbody>
          </table>
          <button type="toggle" onClick={() => setToggle()}>
            {!toggle ? "✅" : "❌"}
            {" useLockBodyScroll "}
            <br />
            {toggle ? "Click to Unlock" : "Click to Lock"}
          </button>
          {toggle ? <LockBody /> : <></>}
        </section>
        <section>
          <h1>Incognito Info</h1>
          <table>
            <tbody>
              <tr className="text-left">
                <th className="pr-1">browserName</th>
                <td>{`${isIncognito.browserName}`}</td>
              </tr>
              <tr className="text-left">
                <th className="pr-1">isIncognito</th>
                <td>
                  {`${isIncognito.isPrivate ? "🥷 " : "💻 "}` +
                    isIncognito.isPrivate}
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
    </>
  );
}
