import { useEffect, useState } from "react";

const usePageLoad = (hideOverlayLayerDelay = 1000) => {
  const [pageLoaded, setPageLoaded] = useState(false);
  const [hideOverlayLayer, setHideOverlayLayer] = useState(false);

  useEffect(() => {
    const handleLoad = () => {
      setPageLoaded(true);

      setTimeout(() => {
        setHideOverlayLayer(true);
      }, hideOverlayLayerDelay);
    };

    if (document.readyState === "complete") {
      handleLoad();
    } else {
      document.addEventListener("readystatechange", () => {
        if (document.readyState === "complete") {
          handleLoad();
        }
      });
    }

    return () => {
      document.removeEventListener("readystatechange", handleLoad);
    };
  }, [hideOverlayLayerDelay]);

  return { pageLoaded, hideOverlayLayer };
};

export default usePageLoad;
