const wsProtocol = window.location.protocol === "https:" ? "wss://" : "ws://";
export const wsServer = `${wsProtocol}${window.location.host}/ws`;
export const defaultAppName = "WebRTC Sharing";
export const nameCharacterSet = "CEFGHJKMNPQRTVWXY";
export const nameLength = 5;

// const ConfigMap = {
//     development: {
//         isProd: false,
//         apiUrl: 'http://localhost:5001',
//         socketUrl: `ws://localhost:5001`,
//     },
//     production: {
//         isProd: true,
//         apiUrl: '',
//         socketUrl: '',
//     },
// }

// const ConfigKey = process?.env.NODE_ENV || 'development'
// const Config = ConfigMap[ConfigKey]

// export default Config
