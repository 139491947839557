import * as React from "react";
import { useBattery } from "@uidotdev/usehooks";

const BatteryInfo = () => {
  const { supported, loading, level, charging, chargingTime, dischargingTime } =
    useBattery();
    let shrunkLevel;
    if (supported)
      shrunkLevel = Math.floor(level * 100); // Converts the string to a number
    else shrunkLevel = 0;
    
  const status = (level) => {
    // const prefix = charging
    //   ? "Charging..."
    //   : "Battery is almost dead (how is this showing?)";
    const chargingEmoji = charging ? "🔌" : "";
    const percentEmoji = level <= 50 ? "🪫" : "🔋";
    return `${chargingEmoji}${percentEmoji} ${level}%`; //| ${prefix} Almost there!

    // if (level < 10) {
    //   return ` ${chargingEmoji}${percentEmoji} ${level}% `; //| ${prefix} Almost there!
    // } else if (level < 30) {
    //   return ` ${chargingEmoji}${percentEmoji} ${level}% `; //| ${prefix} Running on fumes...
    // } else if (level < 60) {
    //   return ` ${chargingEmoji}${percentEmoji} ${level}% `; //| ${prefix} Not bad, but could use a top-up.
    // } else if (level < 80) {
    //   return ` ${chargingEmoji}${percentEmoji} ${level}% `; //| ${prefix} Good to go! 🚗
    // } else if (level < 100) {
    //   return ` ${chargingEmoji}${percentEmoji} ${level}% `; //| ${prefix} Almost fully charged!
    // } else {
    //   return `${chargingEmoji}${percentEmoji} ${level}% `; //| Fully charged and ready to roll! 🚀
    // }
  };
  return (
    <>
      <section>
        {supported ? (
          !loading ? (
            <div className={`${charging ? "charging" : "discharging"}`}>
              <h1>Battery</h1>
              <table>
                <tbody className="text-left">
                  <tr>
                    <th className="pr-1">chargingTime</th>
                    <td>{`${chargingTime}`}</td>
                  </tr>
                  <tr>
                    <th className="pr-1">dischargingTime</th>
                    <td>{`${dischargingTime}`}</td>
                  </tr>
                  <tr>
                    <th className="pr-1">Percentage</th>
                    <td>{`${status(shrunkLevel)}`}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <h2>Loading battery info...</h2>
          )
        ) : (
          <h2>Battery info not supported on this device.</h2>
        )}
      </section>
    </>
  );
};

export default BatteryInfo;
