import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import App from './App';

import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import GoogleAnalytics from "./utils/GoogleAnalytics";
import DOMCleanup from "./utils/DOMCleanup";
import PageSetup from "./utils/PageSetup.js";

import router from "./routes";
import { RouterProvider } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import OfflineMessage from "./parts/offlineMessage";
import Nav from "./parts/Nav/Nav";
import "./i18n.js";

import { Router, Route, Link, Switch } from "wouter";
import App from "./App";
import DonationPage from "./pages/DonatePage/donate";
import SharePage from "./pages/SharePage/share";
// import TechnicalInformation from "./pages/SharePage/TechnicalInformation";
// import MainSharePage from "./pages/SharePage/MainSharePage";
import LoadingPage from "./parts/LoadingPage/loading";

// const root = ReactDOM.createRoot(document.body);
const root = ReactDOM.createRoot(document.getElementById("root"));

const isProduction = process.env.NODE_ENV === "production";

root.render(
  <React.StrictMode>
    {/* <Suspense fallback={<LoadingPage />}> */}
    <PageSetup />
    <DOMCleanup />
    <LoadingPage />
    <div className="max-w-screen-lg m-auto">
      <Nav />
      {isProduction ? <GoogleAnalytics /> : null}
      {isProduction ? <Analytics /> : null}
      {isProduction ? <SpeedInsights /> : null}
      <OfflineMessage />
      {/* <RouterProvider router={router} /> */}
      {/* <RouterProvider router={router} fallbackElement={<LoadingPage />} /> */}
      {/* <Router> */}
      <Switch>
        <Route path="/" component={App} />
        <Route path="/share" component={SharePage} nest />
        <Route path="/donate" component={DonationPage} />

        {/* will match everything else */}
        {/* <Route path="*">
          {(params) => (
            <center>
              <b>404:</b> Sorry, this page <code>"/{params["*"]}"</code> isn't
              ready yet!
            </center>
          )}
        </Route> */}
      </Switch>
    </div>
    {/* </Suspense> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
