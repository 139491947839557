// GoogleAnalytics.js

import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const GoogleAnalytics = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-TMFDKEZJCQ";
    script.async = true;

    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }

      gtag("js", new Date());
      gtag("config", "G-TMFDKEZJCQ");
    };

    return () => {
      // Cleanup when the component unmounts
      document.head.removeChild(script);
    };
  }, []);

  return <Helmet>{/* Add any additional meta tags here if needed */}</Helmet>;
};

export default GoogleAnalytics;
